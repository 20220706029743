import { XccEnvironment } from '@xcc-client/services';

export const environment: XccEnvironment = {
  envMode: 'stage',
  isProduction: false,
  stripeApiPublicKey: 'pk_test_A0mEnva2XUKwspNTDXqg0V4l003joKzWGt',
  xccApiHost: 'https://checkout-api.ecom-stage.idrivesafely.com',
  xgritApiHostUrl: 'https://stg.xgritapi.com/api/1',
  xgritAuthorization: 'Basic MDY5MGI2MDc4ZjBmNTUwOTJlMmFiZDNlMmY3ODk3Zjg6',
  xgritWebAppUrl: 'https://app-stg.idrivesafely.com/user/signin',
  brand: 'ids',
  domain: '.idrivesafely.com',
  clientHost: 'https://www.idrivesafely.com/',
  termsAndConditionsUrl: 'https://www.idrivesafely.com/terms-and-conditions/',
  recaptchaSiteKey: '6LfQdngjAAAAAI51zzbfB69TEqX60eaw2Sqi-JF2',
  stripeBetas: 'elements_enable_deferred_intent_beta_1',
  stripeApiVersion: '""',
  addPurchaseQueryParam: true,
  ambassador: {
    uid: 'a9bfbd56-9ab3-4e72-9f82-29436089bf30',
    isRecordConversionEnabled: true,
  },
  sentryDsn: '',
  passwordConfig: {
    passwordRegEx: '^.{6,}$',
    passwordNotMatchMessage: 'Your password must be at least 6 characters long',
    showPasswordStrength: true,
  },
  temporaryDownurl: 'our-system-is-temporarily-down/',
};
